// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgPost = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.076 3.848a1.25 1.25 0 0 1 1.448-1.014l11.818 2.083a1.25 1.25 0 0 1 1.014 1.449l-2.432 13.787a1.25 1.25 0 0 1-1.448 1.014L4.66 19.083a1.25 1.25 0 0 1-1.014-1.448zm1.708-2.492A2.75 2.75 0 0 0 4.6 3.587L2.168 17.375a2.75 2.75 0 0 0 2.23 3.185l11.818 2.084a2.75 2.75 0 0 0 3.186-2.23l2.43-13.788a2.75 2.75 0 0 0-2.23-3.186zM9.06 5.643A.75.75 0 1 0 8.8 7.12l7.878 1.39a.75.75 0 0 0 .26-1.478zm-1.563 4.548a.75.75 0 0 1 .869-.609l7.878 1.39a.75.75 0 0 1-.26 1.477l-7.879-1.39a.75.75 0 0 1-.608-.868m.174 3.33A.75.75 0 1 0 7.41 15l4.924.868a.75.75 0 1 0 .26-1.477z"
    />
  </svg>
);
export const PostIcon = forwardRef(SvgPost);
